const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  darkBlueColor : '#10226a',
  yellowColor : '#f7941d',
  greyColor : '#333333'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.greyColor,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;